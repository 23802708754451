import { FC, Fragment, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Tag, Grid, Inner, Cell, Button, Row, TopBanner, Col, Container, MobileNavBar, DealerInfo } from "../";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@onemagnify/cxc-components-web";
import Login from "../Dialogs/Login";
import { useNavigate } from "react-router-dom";
import { dealerInfo } from "constants/ModelEDashConstants";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import { RootState } from "store";
import NavItem from "./NavItem";

import { useAuth } from "providers/AuthProvider";
import { hide } from "yargs";
import { setCountryCode } from "reducers/locale-preferences.slice";

// Function to get the current URL path
const isCurrentUrl = () => {
	const location = useLocation();
	return location.pathname.toLocaleLowerCase().replace("/", "");
};

export interface NavItem {
	href?: string;
	label: string;
	aria?: string;
	id?: string;
	key?: string;
	target?: string;
	subMenu?: NavItem[];
}

type HideType = "xs" | "sm" | "md" | "lg" | "xl";

interface NavBarProps {
	tag?: string;
	className?: string;
	hideLinks?: boolean;
	hide?: HideType[];
	navItems: NavItem[];
	transparent?: boolean;
}

const mobileNavItems = [
	{
		label: "Dashboard",
		aria: "Link to: Dashboard",
		key: "dashboard",
		href: "/",
		id: "sectionDashboard",
	},
	{
		label: "Attestations",
		aria: "Attestations",
		key: "attestations",
		href: "attestations",
		id: "sectionAttestations",
	},
	{
		label: "Resources",
		aria: "Resource Links",
		key: "resources",
		href: "resources",
		id: "sectionResources",
	},
	{
		label: "Contact",
		aria: "Link to: Contact Form",
		key: "contact",
		href: "contact",
		id: "ContactForm",
	},
	// {
	// 	label: "Reports",
	// 	aria: "Link to: Reports",
	// 	key: "reports",
	// 	href: "reports",
	// 	id: "sectionReports",
	// },
];

const NavBar: FC<NavBarProps> = (props) => {
	const { tag = "header", navItems, hideLinks, className, ...attributes } = props;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const tokenInfo: any = useAuth();
	const { isDealer, tokenProps } = tokenInfo || {};
	const { authDetails } = tokenProps || {};
	const { paCode: paCodeFromAuth } = authDetails || {};
	const { pacode, marketArea, region, salesCode } = useSelector((state: RootState) => state.pacode);
	const { preferredLanguage } = useSelector((state: RootState) => state.localePreferences);
	const mPaCode = isDealer ? paCodeFromAuth : pacode;

	const mMobileNavItems = useMemo(() => {
		return isDealer
			? mobileNavItems?.filter((item) => item?.id !== "sectionLoaWaivers")
			: // : mobileNavItems;
			  mobileNavItems
					?.filter((item) => item?.id !== "sectionAttestations")
					?.map((item) => ({
						...item,
						label: t(`components.navbar.${item.key}`),
					}));
	}, [isDealer]);

	const windowWidth = useSelector((state: RootState) => state.windowSize.width);

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [menuOpened, setMenuOpened] = useState(false);
	const [selected, setSelected] = useState(false);
	const [navUserData, setNavuserData]: any = useState({});

	const classes = ["om-fmc__navbar", "w-100", "fds-color__bg--white", { "om-fmc__navbar--mobile nav-background-color": windowWidth < 1024 }, { opened: menuOpened }, "fds-wrap--nowrap"];

	function handleSubMenuToggle(id: string = "") {
		if (!mPaCode) {
			setSelected(true);
		}
	}

	const NavBarClassName = clsx(classes, className);

	useEffect(() => {
		setNavuserData(tokenInfo?.tokenProps?.authDetails);
	}, [windowWidth]);

	const [dialogOpen, setDialogOpen] = useState(false);

	const toggleDialog = () => setDialogOpen(!dialogOpen);

	function onNavHandle(event: React.MouseEvent<HTMLElement>, href: string) {
		event.preventDefault();
		const parsedHref = href?.includes(":paCode") ? href?.replace(":paCode", mPaCode) : href;
		navigate(`${parsedHref}`);
		handleSubMenuToggle();
	}

	const filteredNav = navItems.filter((item: any) => {
		if (navUserData.userType !== "Admin") {
			if (navUserData.userType === "Dealer") {
				return item.key !== "reports" && item.key !== "loaWaivers";
			} else if (navUserData.userType === "Employee") {
				return item.key !== "loaWaivers";
			} else {
				return item.key !== "loaWaivers" && item.key !== "reports";
			}
		} else if (navUserData.userType === "Admin") {
			return true;
		} else {
			return true;
		}
	});

	return (
		<Fragment>
			<Tag tag={tag} className={NavBarClassName} {...attributes}>
				<Tag className={"fds-hide--xs fds-hide--sm fds-hide--mobile"}>
					<Container className="program-banner">
						<Row justify={"end"}>
							<LanguageSelector />
						</Row>
					</Container>
				</Tag>

				<Tag tag={"div"} className={"fds-hide--xs fds-hide--sm"}>
					<Grid
						tag={"nav"}
						padding={"1"}
						margin={[
							{ dir: "t", amt: "0" },
							{ dir: "x", amt: "1" },
						]}
						className={"nav-bottom-divider"}
					>
						<Inner>
							<Cell size={12}>
								<Container className=" fds-p--x-0 ">
									<Row justify={"start"}>
										<Col
											className={"w-100"}
											lg={12}
											md={12}
											xl={12}
											padding={[
												{ dir: "y", amt: 2 },
												{ dir: "x", amt: 0 },
											]}
										>
											<Row className={"w-100"}>
												<Col
													md={1}
													lg={2}
													xl={2}
													padding={[
														{
															dir: "l",
															amt: 0,
														},
														{
															dir: "r",
															amt: 0,
														},
													]}
												>
													{preferredLanguage === "fr" ? (
														<Tag tag={"a"} href={"/"} aria-label={"Link to Home Page"}>
															<Tag tag={"img"} className={"fds-img navImg"} src={"/assets/images/FordModelEFR.svg"} alt={"Logo"} />
														</Tag>
													) : (
														<Tag tag={"a"} href={"/"} aria-label={"Link to Home Page"}>
															<Tag tag={"img"} className={"fds-img navImg"} src={"/assets/images/Ford_Model_e_Blue_v2.svg"} alt={"Logo"} />
														</Tag>
													)}
												</Col>
												<Col
													md={7}
													lg={7}
													xl={7}
													padding={[
														{
															dir: "l",
															amt: 0,
														},
														{
															dir: "r",
															amt: 0,
														},
													]}
												>
													<Row
														style={{
															height: "100%",
														}}
													>
														{filteredNav.map(({ label, href, aria, id, target, key, subMenu }) => {
															const classes = [
																"w-100 fds-align--center fds-sm:fds-p--x-0 fds-p--x-2",
																{
																	"fmc-button--active": href?.toLocaleLowerCase().replace("/", "") === isCurrentUrl(),
																},
															];
															return (
																<Fragment key={Math.random()}>
																	{/* Conditional Navigation Buttons (Resources button with chevron dropdown list) */}
																	<NavItem href={href} aria={aria} classes={classes} id={key} subMenu={subMenu} onNavHandle={onNavHandle} allowToggle={!!mPaCode} onSubMenuToggle={handleSubMenuToggle} />
																</Fragment>
															);
														})}
													</Row>
												</Col>
												<DealerInfo />

												<Dialog visible={dialogOpen} onRequestClose={toggleDialog} className={"x-button"} closeOnBackdropClick>
													<Login />
												</Dialog>
											</Row>
										</Col>
									</Row>
								</Container>
							</Cell>
						</Inner>
					</Grid>
					{selected && !mPaCode ? (
						<TopBanner status={"error"} persistent={true} buttonClick={() => setSelected(false)} className={"no-access-success-banner"}>
							{" "}
							Please select Dealer
						</TopBanner>
					) : (
						""
					)}
				</Tag>

				<Tag tag={"div"} className={"fds-hide--md fds-hide--lg fds-hide--xl"}>
					<MobileNavBar
						navItems={mMobileNavItems}
						menuOpened={menuOpened}
						setMenuOpenCallBack={(toggle) => {
							setMenuOpened(toggle);
						}}
						dealerInformation={dealerInfo}
						hideLinks={hideLinks}
					/>
				</Tag>
			</Tag>
		</Fragment>
	);
};

export default NavBar;
